import * as React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Footer from '../components/Footer';
import Container from '../components/Container';
import Header from '../components/Header';
import BuildRelationshipsSection from '../components/sections/BuildRelationshipsSection';

const ordersPricing = [
  {
    min: 0,
    max: 3000,
    price: 0,
  },
  {
    min: 3001,
    max: 5000,
    price: 0.45,
  },
  {
    min: 5001,
    max: 10000,
    price: 0.4,
  },
  {
    min: 10001,
    max: 25000,
    price: 0.35,
  },
  {
    min: 25001,
    max: 50000,
    price: 0.3,
  },
  {
    min: 50001,
    max: Infinity,
    price: 0.25,
  },
];

const paymentPricing = [
  {
    min: 0,
    max: 5000,
    price: 0,
  },
  {
    min: 5001,
    max: 10000,
    price: 0.05,
  },
  {
    min: 10001,
    max: 20000,
    price: 0.04,
  },
  {
    min: 20001,
    max: 50000,
    price: 0.03,
  },
  {
    min: 50001,
    max: 100000,
    price: 0.02,
  },
  {
    min: 100001,
    max: 250000,
    price: 0.01,
  },
  {
    min: 250001,
    max: Infinity,
    price: 0.01,
  },
];

const calculateVolumePricing = (numOrders, numPayments) => {
  const ordersPrice = ordersPricing.reduce((total, tier) => {
    if (numOrders >= tier.min) {
      const unitsInThisTier = Math.min(numOrders, tier.max) - tier.min + 1;
      return total + unitsInThisTier * tier.price;
    }
    return total;
  }, 0);

  const paymentsPrice = paymentPricing.reduce((total, tier) => {
    if (numPayments >= tier.min) {
      const unitsInThisTier = Math.min(numPayments, tier.max) - tier.min + 1;
      return total + unitsInThisTier * tier.price;
    }
    return total;
  }, 0);

  return ordersPrice + paymentsPrice;
};

const tiers = [
  {
    name: 'Growth Plan',
    price: 699,
    additionalCharge: '+1% of GMV',
    features: ['Standard support', 'OOTB Salesforce Commerce Cloud integration', 'OOTB Magento 2 integration'],
    headerBackgroundColor: 'bg-sp-secondary-pastel-green',
    starColor: 'text-sp-text-link',
  },
  {
    name: 'Enterprise Plan',
    price: 2499,
    features: [
      'Everything in Growth Plan',
      'Advanced Email and Shipping Rules',
      'User-Defined Fields (UDFs)',
      'Credit card account updater (AU) available',
    ],
    hasVolumePricing: true,
    headerBackgroundColor: 'bg-sp-secondary-pastel-blue',
    starColor: 'text-sp-text-link',
  },
  {
    name: 'Enterprise Premier',
    price: 3599,
    features: [
      'Everything in Enterprise Plan',
      'Advanced security options',
      'Advanced data residency options',
      'Priority support',
      'Priority SLA commitment',
    ],
    hasVolumePricing: true,
    headerBackgroundColor: 'bg-sp-secondary-pastel-violet',
    starColor: 'text-sp-text-link',
  },
];

const volumePricingInitialState = {
  'Enterprise Plan': {
    orders: 3000,
    payments: 5000,
  },
  'Enterprise Premier': {
    orders: 3000,
    payments: 5000,
  },
};

const VolumePricing = ({ tier, volume, setVolume }) => {
  const handleChange = (sliderType) => (value) => {
    setVolume({ ...volume, [tier.name]: { ...volume[tier.name], [sliderType]: value } });
  };
  return (
    <div className="flex flex-col gap-y-4 mt-4 font-normal mb-1">
      <span className="text-lg m-auto">Monthly successful recurring orders</span>
      <div className="w-full flex justify-between">
        <span>3,000</span>
        <span>50,000</span>
      </div>
      <Slider
        className="slider"
        min={3000}
        max={50000}
        value={volume[tier.name].orders}
        marks={{
          3000: 3000,
          5000: 5000,
          10000: 10000,
          15000: 15000,
          20000: 20000,
          25000: 25000,
          30000: 30000,
          35000: 35000,
          40000: 40000,
          45000: 45000,
          50000: 50000,
        }}
        step={null}
        onChange={handleChange('orders')}
        dotStyle={{ display: 'none' }}
        railStyle={{ backgroundColor: '#D9D9D9', height: '6px' }}
        trackStyle={{ backgroundColor: '#2E7B63', height: '6px' }}
      />
      <span className="text-xl m-auto">{volume[tier.name].orders.toLocaleString('en-US')}</span>
      <span className="text-lg m-auto">Monthly payment transactions</span>
      <div className="w-full flex justify-between">
        <span>5,000</span>
        <span className="text-right">250,000</span>
      </div>
      <Slider
        className="slider"
        value={volume[tier.name].payments}
        min={5000}
        max={250000}
        marks={{
          5000: 5000,
          10000: 10000,
          20000: 20000,
          30000: 30000,
          40000: 40000,
          50000: 50000,
          100000: 100000,
          150000: 150000,
          200000: 200000,
          250000: 250000,
        }}
        step={null}
        onChange={handleChange('payments')}
        dotStyle={{ display: 'none' }}
        railStyle={{ backgroundColor: '#D9D9D9', height: '6px' }}
        trackStyle={{ backgroundColor: '#2E7B63', height: '6px' }}
      />
      <span className="text-xl m-auto">{volume[tier.name].payments.toLocaleString('en-US')}</span>
    </div>
  );
};

const PricingPage = () => {
  const [volume, setVolume] = React.useState(volumePricingInitialState);

  const getPrice = (price, hasVolumePricing, name) => {
    if (hasVolumePricing) {
      const volumePrice = calculateVolumePricing(volume[name].orders, volume[name].payments);
      return `$${(price + volumePrice).toLocaleString('en-US')}`;
    }
    return `$${price}`;
  };

  return (
    <Layout>
      <Seo title="Pricing" />

      <Header graphic="2">Pricing</Header>

      <section className="bg-white text-sp-dark mt-28 mb-28">
        <Container>
          <div className="my-10 flex flex-wrap lg:gap-x-6 lg:mx-12 justify-around xl:flex-nowrap">
            {tiers.map((tier) => (
              <div key={tier.name} className="flex flex-col rounded shadow w-full mt-6 xl:mt-0 mb-2 xl:mb-0 md:w-[46%] xl:w-1/3">
                <div className={`flex flex-col rounded-t items-center h-40 sm:h-44 ${tier.headerBackgroundColor}`}>
                  <h3 id={tier.name} className="text-gray-900 pt-5 text-xl lg:text-2xl font-normal leading-8">
                    {tier.name}
                  </h3>
                  <p className="mt-4 flex">
                    <span className="text-black text-5xl font-normal tracking-tight pl-[20px]">
                      {getPrice(tier.price, tier.hasVolumePricing, tier.name)}
                    </span>
                    <div className="flex flex-col items-start ">
                      <span className={`text-2xl leading-none mb-[-4px] ml-1 ${tier.starColor}`}>*</span>
                      <span className="text-sp-primary-medium-grey text-base font-normal">/ mo</span>
                    </div>
                  </p>
                  {tier.additionalCharge && (
                    <span className="mt-1 text-sp-primary-medium-grey text-base font-light leading-6">
                      {tier.additionalCharge}
                    </span>
                  )}
                </div>
                <div className="flex flex-col px-8 py-8 pb-2 flex-grow">
                  <div className="h-64">
                    <ul className="bullet-circle-check">
                      {tier.features.map((feature) => (
                        <li>{feature}</li>
                      ))}
                    </ul>
                  </div>
                  <a href="/contact" className="btn btn-sp-green w-full inline text-center pt-3">
                    Book a demo
                  </a>
                  {tier.hasVolumePricing && <VolumePricing tier={tier} volume={volume} setVolume={setVolume} />}
                </div>
                <div className="text-sp-text-grey pb-4 text-sm self-center">*Overages may apply.</div>
              </div>
            ))}
          </div>
        </Container>
      </section>

      <BuildRelationshipsSection />

      <Footer />
    </Layout>
  );
};

export default PricingPage;
